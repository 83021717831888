<template>
    <NavMenu
        :hide="hide"
        v-if="validateMenuShow( )"
        v-model="show"
        :main-menu="mainMenu"
        @menu="$emit('menu')"
    >
        <v-list class="py-0">
            <v-list-item class="py-2" style="height: 80px;">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1 font-weight-bold mb-1">
                      <v-expand-x-transition>
                        <v-progress-circular
                            v-if="resellerLoading"
                            class="mr-1"
                            :width="3"
                            :size="20"
                            color="primary"
                            indeterminate
                        />
                      </v-expand-x-transition>
                        <span
                            v-html="
                                resellerData.name ? resellerData.name : 'Loading...'
                            "
                        />
                    </v-list-item-title>
                    <v-list-item-subtitle
                        class="text--primary"
                        v-if="resellerData && resellerData.refnum"
                    >
                      Dealer code {{ resellerData.refnum }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider v-if="$store.state.user.isAdmin" />
            <v-list-item v-if="$store.state.user.isAdmin" style="height: 60px;">
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <v-expand-x-transition>
                          <v-progress-circular
                                v-if="!resellerData || resellerLoading"
                                class="mr-1"
                                :width="2"
                                :size="16"
                                color="primary"
                                indeterminate
                            />
                        </v-expand-x-transition>
                        <span v-if="!resellerData || resellerLoading">Loading...</span>
                        <span v-else-if="resellerData.i_rep">Managed by partner</span>
                        <span v-else-if="resellerData.i_parent">Sub-customer of reseller</span>
                        <span v-else-if="resellerData.i_customer_type == 2">Wholesale User</span>
                        <span v-else>This customer is unmanaged</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="resellerData && (resellerData.i_rep ||resellerData.i_parent)">
                        <div v-if="partnerLoading || resellerLoading">
                            <v-progress-circular
                                class="mr-1"
                                :width="2"
                                :size="14"
                                color="primary"
                                indeterminate
                            />
                            Loading...
                        </div>
                        <div
                                v-if="resellerData.i_parent && !resellerLoading"
                                class="text--secondary text-decoration-underline"
                        >
                          <a>{{resellerData.name || 'Error'}}</a>
                        </div>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item link
                         :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleHome' : 'WholesaleManagedHome' }"
                         :exact-path="true"
            >
                <v-list-item-action>
                    <v-icon v-html="'mdi-account-circle'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Summary</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

          <v-divider />

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleCustomers' : 'WholesaleManagedCustomers' }"
          >
            <v-list-item-action>
              <v-icon v-html="'mdi-account-multiple'" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Customers</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{resellerData.customer_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesalePartners' : 'WholesaleManagedPartners' }"
          >
            <v-list-item-action>
              <v-icon v-html="$vuetify.icons.values.partner" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Partners</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{resellerData.partner_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleContacts' : 'WholesaleManagedContacts' }"
          >
            <v-list-item-action>
              <v-icon v-html="mdiCardAccountDetails" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{resellerData.contact_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleManagedCustomerClasses' : 'WholesaleManagedCustomerClasses' }"
          >
            <v-list-item-action>
              <v-icon v-html="'mdi-account-box'" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Customer Classes</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{resellerData.customer_class_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>


          <v-divider />
          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleAccounts' : 'WholesaleManagedAccounts' }"
          >
            <v-list-item-action>
              <v-icon v-html="mdiPhoneVoip" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>SIP Trunks</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{resellerData.trunk_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleManagedActiveSessions' : 'WholesaleManagedActiveSessions' }"
          >
            <v-list-item-action>
              <v-icon>mdi-phone-log</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Active Calls</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleManagedProducts' : 'WholesaleManagedProducts' }"
          >
            <v-list-item-action>
              <v-icon v-html="'mdi-package-variant-closed'" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{resellerData.product_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleDids' : 'WholesaleManagedDids' }"
          >
            <v-list-item-action>
              <v-icon v-html="mdiNumeric" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>DID Inventory</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleDidSubscriptions' : 'WholesaleManagedDidSubscriptions' }"
          >
            <v-list-item-action>
              <v-icon v-html="mdiNumeric" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>DID Subscriptions</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{resellerData.did_subscription_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-divider />

          <v-list-item link
                       :to="{
            name: $store.state.user.isAdmin ? 'AdminWholesaleManagedInvoiceTemplate' : 'WholesaleManagedInvoiceTemplate',
            params:{wholesaleManagedInvoiceTemplateTag:'default'}
          }"
          >
            <v-list-item-action>
              <v-icon>mdi-receipt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Invoice Template</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link
                       :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleManagedBillingSummary' : 'WholesaleManagedBillingSummary' }"
                       >
            <v-list-item-action>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Billing</v-list-item-title>
            </v-list-item-content>
            <v-fade-transition>
              <v-list-item-action v-if="resellerData && resellerData.unpaid_invoice_info && resellerData.unpaid_invoice_info.total>0">
                <v-chip v-if="resellerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length" small pill style="cursor: pointer;" color="error">{{resellerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length}} overdue</v-chip>
                <v-chip v-else small pill style="cursor: pointer;" color="info">{{resellerData.unpaid_invoice_info.total}}</v-chip>
              </v-list-item-action>
            </v-fade-transition>
          </v-list-item>

          <!--
          <v-divider />
                    <v-list-item link :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleCreateCustomer' : 'WholesaleCreateCustomer' }">
                      <v-list-item-action>
                        <v-icon v-html="'mdi-account-multiple-plus'" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Create Customer</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item link :to="{ name: $store.state.user.isAdmin ? 'AdminWholesaleCreatePartner' : 'WholesaleCreatePartner' }">
                      <v-list-item-action :style="'flex-direction:row;margin-right:22px;'">
                        +<v-icon v-html="$vuetify.icons.values.partner" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Create Partner</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                     <v-divider />
          -->



        </v-list>
        <template v-slot:bottom v-if="$store.state.user.portaone_url">
            <v-list-item tag="a" :href="$store.state.user.portaone_url + (trunkId ? 'account/' + trunkId : 'customer/' + resellerId)" target="_blank">
                <v-list-item-action>
                    <v-icon>mdi-open-in-new</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Open in Porta Admin</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </NavMenu>
</template>

<script>
import { mdiPhoneVoip, mdiNumeric, mdiCardAccountDetails } from '@mdi/js';
import NavMenu from '../templates/NavMenu';
import resellerDataMixin from "../../../mixins/resellerDataMixin";
export default {
    name: 'WholesaleMenu',
    mixins: [resellerDataMixin],
    components: { NavMenu},
    data: () => ({
        show: false,
        mdiPhoneVoip: mdiPhoneVoip,
        mdiNumeric: mdiNumeric,
        mdiCardAccountDetails: mdiCardAccountDetails,
    }),
    props: {
        value: {
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        mainMenu: {
            type: Boolean,
            default: false,
        },
    },
  methods:{
      validateMenuShow( ){
        if(!this.$store.state.user.isLoggedIn){
          return false;
        }

        if(!this.$store.state.user.isAdmin){
          return true;
        }else if(!this.$store.state.user.isManagedWholesale){
          return true;
        }

        return false;
      }
  },
    computed: {
        trunkView() {
            return this.$route.matched.some((m) => m.name === 'CustomerTrunk') || this.$route.matched.some((m) => m.name === 'AdminCustomerTrunk');
        },
        trunkId() {
            return parseInt(this.$route.params.trunkId);
        },
        partnerLoading() {
            return this.$store.state.loading.partner;
        },
        partnerData() {
            if (
                this.customerData.i_rep &&
                this.$store.state.data.partner &&
                this.customerData.i_rep === this.$store.state.data.partner.i_rep
            ) {
                return this.$store.state.data.partner;
            }
            return [];
        },
        // resellerLoading() {
        //     return this.$store.state.loading.reseller;
        // },
        // resellerData() {
        //     if (
        //         this.customerData.i_parent &&
        //         this.$store.state.data.reseller &&
        //         this.customerData.i_parent === this.$store.state.data.reseller.i_customer
        //     ) {
        //         return this.$store.state.data.reseller;
        //     }
        //     return [];
        // },
    },
    watch: {
        show: function (val) {
            this.$emit('input', val);
        },
        value: function (val) {
            this.show = val;
        },
    },
    created() {
        this.show = this.value;
    },
};
</script>
