var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('router-view',{attrs:{"name":"menu","hide":_vm.mobileView},on:{"menu":_vm.showMainMenu},model:{value:(_vm.view.secondaryMenu),callback:function ($$v) {_vm.$set(_vm.view, "secondaryMenu", $$v)},expression:"view.secondaryMenu"}}),(_vm.$store.state.user.isAdmin)?_c('AdminMenu',{attrs:{"hide":_vm.mobileView || _vm.$route.matched.some(function (m) { return m.components.menu; })},model:{value:(_vm.view.mainMenu),callback:function ($$v) {_vm.$set(_vm.view, "mainMenu", $$v)},expression:"view.mainMenu"}}):_vm._e(),(_vm.$store.state.user.isPartner)?_c('PartnerMenu',{attrs:{"hide":_vm.mobileView || _vm.$route.matched.some(function (m) { return m.components.menu; }),"main-menu":""},model:{value:(_vm.view.mainMenu),callback:function ($$v) {_vm.$set(_vm.view, "mainMenu", $$v)},expression:"view.mainMenu"}}):_vm._e(),(_vm.$store.state.user.isManagedWholesale)?_c('WholesaleMenu',{attrs:{"hide":_vm.mobileView || _vm.$route.matched.some(function (m) { return m.components.menu; }),"main-menu":""},model:{value:(_vm.view.mainMenu),callback:function ($$v) {_vm.$set(_vm.view, "mainMenu", $$v)},expression:"view.mainMenu"}}):_vm._e(),(_vm.$store.state.user.isLoggedIn)?_c('ApplicationBar',{attrs:{"menu-button":_vm.mobileView || (_vm.$route.matched.some(function (m) { return m.components.menu; }) && _vm.$store.state.user.isNotCustomer)},on:{"menu":_vm.showMenu}}):_vm._e(),_c('Banner',{attrs:{"icon":"mdi-alert","message":"Your account is not protected by two-factor authentication. We recommend setting it up now.","color":"warning","accept":"Go to My Account","dismiss":"Dismiss","show":_vm.$store.state.user.isLoggedIn &&
            !_vm.$store.state.user.twofactor_enabled &&
            _vm.view.showTwoFactorBanner},on:{"accept":_vm.setup2fa,"dismiss":function($event){_vm.view.showTwoFactorBanner = false}}}),(
                (!_vm.$route.matched.some(function (m) { return m.meta.public; }) && !_vm.$store.state.user.isLoggedIn) ||
                _vm.view.update.inProgress
            )?_c('SpinnerOverlay',{attrs:{"opacity":(!_vm.$store.state.user.isLoggedIn || _vm.view.update.inProgress) ? 1 : undefined,"message":_vm.view.update.inProgress ? 'Updating...' : 'Loading...'}}):_vm._e(),_c('v-main',{staticClass:"fill-height",attrs:{"id":"main","tag":"main"}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view',{attrs:{"name":"toolbar"}})],1),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view',{key:"mainView"})],1)],1),(!_vm.$route.matched.some(function (m) { return m.meta.public; }))?_c('div',[_vm._l((_vm.$store.state.errors),function(error,index){return _c('ApplicationError',{key:'error-message-' + index,attrs:{"error":error,"index":index}})}),(_vm.$store.state.user.isLoggedIn && _vm.view.update.available)?_c('ApplicationUpdateDialog',{attrs:{"visible":!_vm.view.update.dismissed && !_vm.view.update.inProgress},on:{"update":_vm.updateApplication,"cancel":function($event){_vm.view.update.dismissed=true}}}):_vm._e(),(_vm.$store.state.user.isLoggedIn && _vm.view.update.available && _vm.view.update.dismissed && !_vm.view.update.inProgress)?_c('ApplicationUpdateFooter',{on:{"update":_vm.updateApplication}}):_vm._e()],2):_vm._e(),_c('v-snackbars',{attrs:{"objects":_vm.snackbarObjects,"top":"","app":"","content-class":"bcsnackbar"},on:{"update:objects":function($event){_vm.snackbarObjects=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var message = ref.message;
return [(message != null && message.indexOf('success:') > -1)?_c('v-layout',{attrs:{"align-center":"","pr-4":""}},[_c('v-icon',{staticClass:"pr-3",attrs:{"dark":"","large":""}},[_vm._v("mdi-check-circle-outline")]),_c('v-layout',{attrs:{"column":""}},[_vm._v(" "+_vm._s(message.replace("success:", ""))+" ")])],1):(message != null && message.indexOf('error:') > -1)?_c('v-layout',{attrs:{"align-center":"","pr-4":""}},[_c('v-icon',{staticClass:"pr-3",attrs:{"dark":"","large":""}},[_vm._v("mdi-alert-circle-outline")]),_c('v-layout',{attrs:{"column":""}},[_vm._v(" "+_vm._s(message.replace("error:", ""))+" ")])],1):_c('v-layout',{attrs:{"align-center":"","pr-4":""}},[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"action",fn:function(ref){
            var close = ref.close;
return [_c('v-icon',{staticClass:"pr-3",attrs:{"dark":""},on:{"click":function($event){return close( )}}},[_vm._v("mdi-close")])]}}])}),(_vm.view.showDevelopmentFooter && false)?_c('ApplicationFooter'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }