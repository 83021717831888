<template>
    <v-app>
        <!-- Navigation menu -->
        <router-view
            name="menu"
            v-model="view.secondaryMenu"
            :hide="mobileView"
            @menu="showMainMenu"
        />
        <AdminMenu
            v-if="$store.state.user.isAdmin"
            v-model="view.mainMenu"
            :hide="mobileView || $route.matched.some((m) => m.components.menu)"
        />
        <PartnerMenu
            v-if="$store.state.user.isPartner"
            v-model="view.mainMenu"
            :hide="mobileView || $route.matched.some((m) => m.components.menu)"
            main-menu
        />
        <WholesaleMenu
            v-if="$store.state.user.isManagedWholesale"
            v-model="view.mainMenu"
            :hide="mobileView || $route.matched.some((m) => m.components.menu)"
            main-menu
        />
        <!-- Application Bar -->
        <ApplicationBar
            v-if="$store.state.user.isLoggedIn"
            @menu="showMenu"
            :menu-button="
                mobileView || ($route.matched.some((m) => m.components.menu) && $store.state.user.isNotCustomer)
            "
        />
        <!-- 2fa alert banner -->
        <Banner
            icon="mdi-alert"
            message="Your account is not protected by two-factor authentication. We recommend setting it up now."
            color="warning"
            accept="Go to My Account"
            dismiss="Dismiss"
            :show="
                $store.state.user.isLoggedIn &&
                !$store.state.user.twofactor_enabled &&
                view.showTwoFactorBanner
            "
            @accept="setup2fa"
            @dismiss="view.showTwoFactorBanner = false"
        />
        <SpinnerOverlay
                v-if="
                    (!$route.matched.some((m) => m.meta.public) && !$store.state.user.isLoggedIn) ||
                    view.update.inProgress
                "
                :opacity="(!$store.state.user.isLoggedIn || view.update.inProgress) ? 1 : undefined"
                :message="view.update.inProgress ? 'Updating...' : 'Loading...'"
        />
        <!-- Content -->
        <v-main id="main" tag="main" class="fill-height">
            <!-- toolbar -->
            <v-fade-transition mode="out-in">
              <router-view name="toolbar" />
            </v-fade-transition>

            <!-- main content -->
            <v-fade-transition mode="out-in">
                <router-view key="mainView"></router-view>
            </v-fade-transition>
        </v-main>
        <!-- content to only show on non-public routes -->
        <div v-if="!$route.matched.some((m) => m.meta.public)">
            <!-- Application Errors -->
            <ApplicationError v-for="(error, index) of $store.state.errors" :key="'error-message-' + index" :error="error" :index="index" />
            <!-- Application Updates -->
            <ApplicationUpdateDialog
                v-if="$store.state.user.isLoggedIn && view.update.available"
                :visible="!view.update.dismissed && !view.update.inProgress"
                @update="updateApplication"
                @cancel="view.update.dismissed=true"
            />
            <ApplicationUpdateFooter v-if="$store.state.user.isLoggedIn && view.update.available && view.update.dismissed && !view.update.inProgress" @update="updateApplication" />
        </div>
      <v-snackbars :objects.sync="snackbarObjects"
                   top
                   app
                   content-class="bcsnackbar">
        <template v-slot:default="{message}" @click="alert('test')">
          <v-layout align-center pr-4 v-if="message != null && message.indexOf('success:') > -1">
            <v-icon class="pr-3" dark large>mdi-check-circle-outline</v-icon>
            <v-layout column>
              {{ message.replace("success:", "") }}
            </v-layout>
          </v-layout>
          <v-layout align-center pr-4 v-else-if="message != null && message.indexOf('error:') > -1">
            <v-icon class="pr-3" dark large>mdi-alert-circle-outline</v-icon>
            <v-layout column>
              {{ message.replace("error:", "") }}
            </v-layout>
          </v-layout>
          <v-layout v-else align-center pr-4>
            {{ message}}
          </v-layout>
        </template>
        <template v-slot:action="{close}">
          <!-- hack to hide close button with {{null}}-->
          <v-icon class="pr-3" dark @click="close( )">mdi-close</v-icon>
        </template>
      </v-snackbars>
        <!-- Footer -->
        <ApplicationFooter v-if="view.showDevelopmentFooter && false" />
    </v-app>
</template>

<script>
import ApplicationBar from './components/core/ApplicationBar';
import ApplicationFooter from './components/core/ApplicationFooter';
import Banner from './components/core/Banner';
import SpinnerOverlay from './components/pieces/Global/SpinnerOverlay';
import AdminMenu from './components/core/menu/AdminMenu';
import PartnerMenu from './components/core/menu/PartnerMenu';
import ApplicationError from './components/core/ApplicationError';
import ApplicationUpdateFooter from './components/core/update/ApplicationUpdateFooter';
import ApplicationUpdateDialog from './components/core/update/ApplicationUpdateDialog';
import VSnackbars from "v-snackbars";
import WholesaleMenu from "./components/core/menu/WholesaleMenu";

export default {
    name: 'App',

    components: {
      WholesaleMenu,
        ApplicationUpdateDialog,
        ApplicationUpdateFooter,
        ApplicationError,
        PartnerMenu,
        AdminMenu,
        SpinnerOverlay,
        Banner,
        ApplicationBar,
        ApplicationFooter,
        "v-snackbars":VSnackbars,
    },
    data: () => ({
        view: {
            mainMenu: false,
            secondaryMenu: false,
            showTwoFactorBanner: true,
            showDevelopmentFooter: process.env.VUE_APP_DEBUG === 'true',
            update: {
                available: false,
                dismissed: false,
                inProgress: false
            }
        },
        registration: null,
        snackbarObjects:[],
    }),
    methods: {
        showMenu: function () {
            if (
                this.mobileView &&
                this.$route.matched.some((m) => m.components.menu)
            ) {
                this.view.secondaryMenu = true;
            } else {
                this.view.mainMenu = true;
            }
        },
        showMainMenu: function () {
            this.view.secondaryMenu = false;
            this.view.mainMenu = true;
        },
        showBanner: function () {
            this.view.showBanner = true;
        },
        setup2fa: function () {
            this.view.showTwoFactorBanner = false;
            this.$router.push({ name: 'MyAccount' });
        },
        updateAvailable(e) {
            this.registration = e.detail;
            this.view.update.available = true;
        },
        updateApplication() {
            this.view.update.inProgress = true;
            if (this.registration || this.registration.waiting)
                this.registration.waiting.postMessage({type:'SKIP_WAITING'});
        },
    },
    computed: {
        mobileView() {
            return this.$vuetify.breakpoint.smAndDown;
        },

      snackbarObjectStore( ){
        return this.$store.state.data.snackbarObjectStore;
      }

    },
    created() {
        // check current dark/light theme preference
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.$vuetify.theme.dark = true;
        }
        // listen for changes to dark/light theme preference
        window.matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (event) => {
                this.$vuetify.theme.dark = event.matches;
            });
        // listen for update event from service worker to trigger user notifications
        document.addEventListener(
            'serviceWorkerUpdateEvent', this.updateAvailable, { once: true }
        );
        // listen for update to controller and refresh
        navigator.serviceWorker.addEventListener(
            'controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            }
        );
    },
    watch: {
        $route(to, from) {
            this.view.mainMenu = false;
            this.$vuetify.goTo(0);
            if (from.meta.public && !to.meta.public && this.view.update.available)
                this.updateApplication();
        },
        mainMenu(val) {
            if (!val) this.view.secondaryMenu = true;
        },
      snackbarObjectStore(val){
        if(val != null) {
          //this.showSuccessSnackBar = true;
          this.snackbarObjects.push(val)
          this.$store.commit('data', {
            name:  'snackbarObjectStore',
            data: null,
          });
          //setTimeout(() => {this.showSuccessSnackBar = false}, 5000);
        }
      },
    },

};
</script>
