<template>
    <v-tooltip :disabled="tooltipDisabled" :small="small" bottom open-delay="200">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn
                :icon="!text"
                :small="small"
                :color="color"
                :text="flat"
                :href="href"
                :target="target"
                :disabled="disabled"
                :block="block"
                :class="paddingRight ? 'mr-2' : ''"
                :to="to"
                exact
                @click="$emit('click')"
            >
                <v-icon
                    v-if="icon && !iconRight"
                    :left="!!text"
                    :small="small"
                    >{{ icon }}</v-icon
                >{{text}}<v-icon
                    v-if="icon && iconRight"
                    right="right"
                    :small="small"
                    >{{ icon }}</v-icon
                ></v-btn
            ></span>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'ButtonWithTooltip',
    // watch: {
    //     show: function (value) {
    //         this.visible = value;
    //     },
    // },
    props: {
        icon: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        to: {
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        iconRight: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        paddingRight: {
            type: Boolean,
            default: false,
        },
        hrefNewWindow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tooltipDisabled: function () {
            return !this.tooltip;
        },
        target: function () {
            return this.href && this.hrefNewWindow ? '_blank' : null;
        },
    },
    // created() {
    //     if (this.show) {
    //         this.visible = true;
    //     }
    // },
};
</script>
