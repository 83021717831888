export default {
    data: () => ({
        detail: null,
    }),
    mounted() {
        document.addEventListener('refreshRequested', this.refreshHandler);
    },
    beforeDestroy() {
        document.removeEventListener('refreshRequested', this.refreshHandler);
    },
    methods: {
        refreshHandler(event) {
            this.detail = event.detail;
            this.update();
        },
        update() {
            if(process.env.VUE_APP_DEBUG) {
                let msg = 'Refresh update requested however components does not have a update method. Refresh included in parent ('+this.$parent.$options._componentTag+'/'+this.$parent.$options.name+')';
                console.log(msg);
                if(this.detail != null){
                    console.log(this.detail);
                }
            }
        }
    },
};