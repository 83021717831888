export default {
    data: function() {
        return {
           // snackbarObjects:[],
            //showSuccessSnackBar:false,
        }
    },
    methods: {
        showGlobalSuccessMessage(message){
            this.showSnackbarMessage('success',message);
        },
        showGlobalErrorMessage(message){
            this.showSnackbarMessage('error',message);
        },

        showSnackbarMessage(type, message){
          const validTypes = [
              'success',
              'error',
              'warning'
          ];

          if(!validTypes.includes(type)){
              console.error("Invalid message type of ("+type+").");
              throw "Invalid message type of ("+type+").";
          }
            this.$store.commit('data', {
                name: 'snackbarObjectStore',
                data: {
                    message:type+':'+message,
                    color:type,
                    timeout:5000
                }
            });

        },
        convertToQueryString(obj, prefix){
            var str = [],
                p;
            for (p in obj) {
                if (obj.hasOwnProperty(p)) {
                    var k = prefix ? prefix + "[" + p + "]" : p,
                        v = obj[p];
                    str.push((v !== null && typeof v === "object") ?
                        this.convertToQueryString(v, k) :
                        encodeURIComponent(k) + "=" + encodeURIComponent(v));
                }
            }
            return str.join("&");
        }
    },
    filters: {
        currency: function(value){
            if (typeof value !== "number") {
                return value;
            }
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'AUD',
                currencyDisplay:'narrowSymbol',
            });
            return formatter.format(value);
        },
        thousandSeparator: function(value) {
            if (!value) value = 0;

            let decimal = false;
            if(value % 1 != 0){
                decimal = true;
            }

            let ret = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            if(!decimal){
                ret = ret+'.00';
            }
            return ret;
        },
    }

};