<template>
    <v-menu
        v-model="showResults"
        transition="slide-y-transition"
        origin="top center"
        close-on-content-click
        bottom
        offset-y
    >
        <template v-slot:activator="{ on }">
            <v-theme-provider root>
                <v-expand-x-transition>
                    <v-text-field
                        v-show="visible"
                        v-on="on"
                        :dark="!hasFocus && isEmpty && !$vuetify.theme.isDark"
                        @click="searchFocus"
                        @input="searchUpdated"
                        @focus="hasFocus = true"
                        @blur="hasFocus = false"
                        :solo="hasFocus || !isEmpty"
                        :outlined="!hasFocus && isEmpty"
                        :loading="loading"
                        label="Quick search"
                        dense
                        clearable
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        single-line
                        full-width
                        :autofocus="autofocus"
                        v-model="search"
                    />
                </v-expand-x-transition>
            </v-theme-provider>
        </template>
        <v-list
            id="searchresults"
            key="searchresults"
            two-line
            dense
            v-if="search &&
                (results.resellers ||
                results.representatives ||
                results.customers ||
                results.accounts ||
                results.signups)
            "
            max-width="500px"
            max-height="500px"
            class="overflow-y-auto"
        >

          <v-list-item
              @click="optionSelected"
              v-for="item in results.resellers"
              :key="'c' + item.i_customer"
              :to="{
                    name: 'AdminWholesaleHome',
                    params: { customerId: item.i_customer },
                }"
          >
            <v-list-item-content>
              <v-list-item-title>
                <MarkedText
                    :text="item.name"
                    :mark-text="searchQuery"
                />
              </v-list-item-title>
              <v-list-item-subtitle>
                <MarkedText
                    :text="String(item.i_customer)"
                    :mark-text="searchQuery"
                    label="ID"
                    hide-unmatched
                    no-partial-match
                    class="mr-2"
                />
                <MarkedText
                    :text="item.companyname"
                    :mark-text="searchQuery"
                    label="Company"
                    class="mr-2"
                />
                <MarkedText
                    v-if="item.email"
                    :text="item.email"
                    :mark-text="searchQuery"
                    label="Email"
                    hide-unmatched
                    class="mr-2"
                />
                <MarkedText
                    v-if="item.firstname || item.lastname"
                    :text="item.firstname + ' ' + item.lastname"
                    :mark-text="searchQuery"
                    label="Contact"
                    hide-unmatched
                    class="mr-2"
                />
                <MarkedText
                    v-if="item.phone1"
                    :text="item.phone1"
                    :mark-text="searchQuery"
                    label="Phone"
                    hide-unmatched
                    class="mr-2"
                />
                <MarkedText
                    v-if="item.phone2"
                    :text="item.phone2"
                    :mark-text="searchQuery"
                    label="Mobile"
                    hide-unmatched
                    class="mr-2"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="align-center">
              <v-chip small class="primary lighten-2"
              >Managed Wholesale User</v-chip
              >
            </v-list-item-action>
          </v-list-item>


            <v-list-item
                @click="optionSelected"
                v-for="item in results.representatives"
                :key="'i' + item.i_rep"
                :to="{
                    name: 'AdminPartnerSummary',
                    params: { partnerId: item.i_rep },
                }"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <MarkedText
                            :text="item.name"
                            :mark-text="searchQuery"
                        />
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <MarkedText
                            :text="String(item.i_rep)"
                            :mark-text="searchQuery"
                            label="ID"
                            hide-unmatched
                            no-partial-match
                            class="mr-2"
                        />
                        <MarkedText
                            :text="item.companyname"
                            :mark-text="searchQuery"
                            label="Company"
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.refnum"
                            :text="item.refnum"
                            :mark-text="searchQuery"
                            label="Dealer Code"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.email"
                            :text="item.email"
                            :mark-text="searchQuery"
                            label="Email"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.firstname || item.lastname"
                            :text="item.firstname + ' ' + item.lastname"
                            :mark-text="searchQuery"
                            label="Contact"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.phone1"
                            :text="item.phone1"
                            :mark-text="searchQuery"
                            label="Phone"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.phone2"
                            :text="item.phone2"
                            :mark-text="searchQuery"
                            label="Mobile"
                            hide-unmatched
                            class="mr-2"
                        />
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-center">
                    <v-chip small class="primary">Partner</v-chip>
                </v-list-item-action>
            </v-list-item>
            <v-list-item
                @click="optionSelected"
                v-for="item in results.customers"
                :key="'c' + item.i_customer"
                :to="{
                    name: 'CustomerSummary',
                    params: { customerId: item.i_customer },
                }"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <MarkedText
                            :text="item.name"
                            :mark-text="searchQuery"
                        />
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <MarkedText
                            :text="String(item.i_customer)"
                            :mark-text="searchQuery"
                            label="ID"
                            hide-unmatched
                            no-partial-match
                            class="mr-2"
                        />
                        <MarkedText
                            :text="item.companyname"
                            :mark-text="searchQuery"
                            label="Company"
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.email"
                            :text="item.email"
                            :mark-text="searchQuery"
                            label="Email"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.firstname || item.lastname"
                            :text="item.firstname + ' ' + item.lastname"
                            :mark-text="searchQuery"
                            label="Contact"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.phone1"
                            :text="item.phone1"
                            :mark-text="searchQuery"
                            label="Phone"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.phone2"
                            :text="item.phone2"
                            :mark-text="searchQuery"
                            label="Mobile"
                            hide-unmatched
                            class="mr-2"
                        />
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-center">
                    <v-chip small class="primary lighten-2"
                        >Customer</v-chip
                    >
                </v-list-item-action>
            </v-list-item>

            <v-list-item
                @click="optionSelected"
                v-for="item in results.accounts"
                :key="'a' + item.i_account"
                :to="{
                    name: 'TrunkSummary',
                    params: {
                        customerId: item.i_customer,
                        trunkId: item.i_account,
                    },
                }"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <MarkedText
                            :text="item.id"
                            :mark-text="searchQuery"
                        />
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <MarkedText
                            :text="item.i_account"
                            :mark-text="searchQuery"
                            label="ID"
                            hide-unmatched
                            no-partial-match
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.customer_name"
                            :text="item.customer_name"
                            :mark-text="searchQuery"
                            label="Customer"
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.email"
                            :text="item.email"
                            :mark-text="searchQuery"
                            label="Email"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.firstname || item.lastname"
                            :text="item.firstname + ' ' + item.lastname"
                            :mark-text="searchQuery"
                            label="Contact"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.phone1"
                            :text="item.phone1"
                            :mark-text="searchQuery"
                            label="Phone"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.phone2"
                            :text="item.phone2"
                            :mark-text="searchQuery"
                            label="Mobile"
                            hide-unmatched
                            class="mr-2"
                        />
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-center">
                    <v-chip small class="secondary">SIP Trunk</v-chip>
                </v-list-item-action>
            </v-list-item>

            <v-list-item
                @click="optionSelected"
                v-for="item in results.signups"
                :key="'c' + item.signup_id"
                :to="'/admin/signups/' + item.signup_id"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <MarkedText
                            :text="item.companyname"
                            :mark-text="searchQuery"
                        />
                        {{ item.companyname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <MarkedText
                            v-if="item.refnum"
                            :text="item.refnum"
                            :mark-text="searchQuery"
                            label="Dealer Code"
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.email"
                            :text="item.email"
                            :mark-text="searchQuery"
                            label="Email"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.firstname || item.lastname"
                            :text="item.firstname + ' ' + item.lastname"
                            :mark-text="searchQuery"
                            label="Contact"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.contact_business_phone"
                            :text="item.contact_business_phone"
                            :mark-text="searchQuery"
                            label="Phone"
                            hide-unmatched
                            class="mr-2"
                        />
                        <MarkedText
                            v-if="item.contact_mobile"
                            :text="item.contact_mobile"
                            :mark-text="searchQuery"
                            label="Mobile"
                            hide-unmatched
                            class="mr-2"
                        />
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-center">
                    <v-chip small class="primary darken-2"
                        >Signup</v-chip
                    >
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-list
            key="noresults"
            dense
            v-if="
                search &&
                !results.representatives &&
                !results.customers &&
                !results.accounts &&
                !results.signups
            "
        >
            <v-list-item>
                <v-list-item-content>
                    No results found
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import MarkedText from '../pieces/MarkedText';
import apiMixin from '../../mixins/apiMixin';
export default {
    name: 'QuickSearch',
    mixins: [apiMixin],
    components: { MarkedText },
    data: () => ({
        loading: false,
        searchQuery: '',
        timer: null,
        results: [],
        showResults: false,
        search: '',
        hasFocus: false,
    }),
    computed: {
        isEmpty() {
            return !this.search || this.search === '';
        },
    },
    methods: {
        searchFocus: function () {
            if (this.search && this.search.length > 2) {
                this.showResults = true;
            }
        },
        optionSelected() {
            this.$emit('close');
            this.search='';
        },
        searchUpdated(query) {
            clearTimeout(this.timer);
            this.showResults = false;
            if (query) {
                query = query.replace(/^\s+|\s+$/g, '');
                if (this.search !== query) this.search = query;
            }
            if (!this.isEmpty && (query.length > 2 || /^\d+$/.test(query))) {
                this.timer = setTimeout(() => {
                    if (query !== this.searchQuery) {
                        this.fetchResults(query);
                    }
                }, 500); // 0.5 sec delay
            } else {
                this.results = [];
                this.searchQuery = '';
                this.showResults = false;
            }
        },
        async fetchResults(query) {
            this.loading = true;
            this.searchQuery = query;
            const response = await this.Api.send('post','search/quick', {
                query: query,
            });
            this.loading = false;
            if (response.success) {
                this.results = response.data;
                this.showResults = true;
            } else {
                //this.updateFailed(response.error, response.errorDetail);
            }
        },
    },
    props: {
        autofocus: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: true
        },
    },
};
</script>
