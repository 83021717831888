import apiMixin from './apiMixin';
import refreshListenerMixin from './refreshListenerMixin';
export default {
    mixins: [apiMixin, refreshListenerMixin],
    computed: {
        partnerLoading() {
            return this.$store.state.loading.partner;
        },
        partnerData() {
            if (
                this.$store.state.data.partner && (this.$store.state.data.partner.i_rep === parseInt(this.$route.params.partnerId)
                    || this.$store.state.user.user_type === 'partner')
            ) {
                return this.$store.state.data.partner;
            }
            return [];
        },
    },

    methods: {
        async get(id, force) {
            if (
                force ||
                !this.$store.state.data.partner ||
                this.$store.state.data.partner.i_rep !== parseInt(id)
            ) {
                if(id == null){
                    //Assuming loggedin user is a partner. Take data from session
                    if(this.$store.state.user.user_type == 'partner'){
                        id = this.$store.state.user.partner.i_rep;
                    }else{
                        //no id is passed and logged in user is not a partner.
                        this.updateFailed();
                        return;
                    }
                }
                this.$store.commit('loading', { name: 'partner', data: true });
                const response = await this.Api.send('get','partners/' + id);
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'partner',
                        data: response.data,
                    });
                } else {
                    this.updateFailed(response.error, response.errorDetail);
                }
                this.$store.commit('loading', { name: 'partner', data: false });
            }
            if (this.$store.state.user.user_type === 'admin' && this.partnerData.i_customer){
                await this.getPartnerReseller(this.partnerData.i_customer);
            }
        },
        async getPartnerReseller(id) {
            if (
                this.$store.state.data.partnerReseller &&
                this.$store.state.data.partnerReseller.i_customer === parseInt(id)
            ) {
                this.$store.commit('loading', {
                    name: 'partnerReseller',
                    data: false,
                });
            } else {
                this.$store.commit('loading', { name: 'partnerReseller', data: true });
                const response = await this.Api.send('get','customers/' + id);
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'partnerReseller',
                        data: response.data,
                    });
                    this.$store.commit('loading', {
                        name: 'partnerReseller',
                        data: false,
                    });
                }
            }
        },
		/**
		 * I do not believe that this method is used.
		 * I'm unable to locate there this method is being called.
		 **/
        refresh() {
            this.get(this.$route.params.partnerId, true);
        },
        //uses the refreshListenerMixin to update the page when the refresh icon is selected
        update() {
            this.get(this.$route.params.partnerId, true);
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
    },
    //We we run this on create this process to update runs for each file the mixin is included in. It runs too many times.
    // It has since been moved to the PartnerParent
    // created() {
    //     this.get(this.$route.params.partnerId);
    // },

};