import apiMixin from './apiMixin';

/**
 * This mixin was created due to an issue when Managed Wholesaler user were logged in and access their customers.
 * Is issue is that wholesale user needs to use the customerDataMixin for their login details and will also use the customerDataMixin
 * for when viewing their own customers. Hence the customerDataMixin was in use for 2 customer records (1 for the logged in wholesale user and
 * one for the current customer being viewed) when it is only designed to cater for 1.
 * Hence the resellerDataMixin is created to cater for the Managed Wholesale Users Customer record.
 * Note a Customer records with customer_type of 2 is considered a reseller in PortaOne.
 *
 * Note that this mixin only need to cater for the returning of reseller data for a logged in reseller.
 * Any other logic on a reseller is conducted with the customerDataMixin as reseller records are customer records with an i_customer_type of 2.
 * EG If admin is viewing a reseller (Managed Wholesale user) then we would use the customerDataMixin as resellers are customer records.
 * If a reseller is viewing one of their customers then we would need to use the customerDataMixin to return the customer data
 * and we would need to use the resellerDataMixin to return the logged in resellers data.
 */
export default {
    mixins: [apiMixin],
    data: () => ({

    }),
    computed: {
        resellerLoading() {
            return this.$store.state.loading.reseller;
        },
        /**
         * Returns the stored reseller data if the stored customer data i_customer = the i_customer in the  query string.
         * Else if the logged in user has a customer record it will check if the stored reseller data matches the logged in user.
         * If so it is returned.         *
         * @returns {null|*|{}}
         */
        resellerData() {
            if (
                (this.$store.state.user.user_type === 'wholesale-managed' && this.$store.state.data.reseller && this.$store.state.data.reseller.i_customer_type == 2 && this.$store.state.data.reseller.i_customer == this.$store.state.user.customer.i_customer)
                ||
                (this.$store.state.user.user_type === 'admin' && this.$store.state.data.reseller && this.$store.state.data.reseller.i_customer_type == 2)
            ) {
                return this.$store.state.data.reseller;
            }
            return {};
        },
        /**
         * Return the customer id based on a few different checks.
         * The reason for these checks here is that the customer UI components have multiple use cases.
         * The main issue being they can be used to show the details of the logged in user (if matched to a customer record. EG Byo Users).
         * Or the customer UI components can be used to view customers owned or managed by the logged in user.
         * Hence this method to pull the customer id is built to cater for this so the components get an accurate UI.
         *
         * First it will return the customer id in the URL.
         * Then it will check if the current stores customer data matches the current logged in user. If so that customer id is return.
         * Else it will check if the logged in user has a linked customer record and return the i_customer for the record from the returned sessions values.
         *
         * @returns {null|number|*}
         */

        resellerId() {
            if(!this.$route.params.customerId){
                let resellerData = this.resellerData;
                if(!resellerData || !resellerData.i_customer){ //note that this.resellerData can return an empty object {}
                    //check to see if any reseller data is loaded.
                    if(this.$store.state.data.reseller){
                        //invalid
                        if(process.env.VUE_APP_DEBUG){
                            console.error('Failed to return resellerId from resellerDataMixin. 01');
                        }
                        return null;
                    }else{
                        //check to see if the logged in user is a customer
                        if(this.$store.state.user.user_type == 'wholesale-managed'){
                            //we return the id from the session
                            return this.$store.state.user.customer.i_customer;
                        }else{
                            //invalid
                            if(process.env.VUE_APP_DEBUG){
                                console.error('Failed to return resellerId from resellerDataMixin. 02');
                            }
                            return null;
                        }
                    }
                }else{
                    return resellerData.i_customer;
                }
            }else{
                return parseInt(this.$route.params.customerId);
            }
        },

        forcedCustomerMixinType( ){
            return this.$store.state.data.forcedCustomerMixinType??null;
        },
    },
    methods: {
        /**
         * Retrieves the customer data. Either by making a call to the API or returning what is stored in storage.
         *
         * @param id i_customer
         * @param force if set to true will force the process to call the data from the API.
         * @param type what type of customer either
         * 'customer'
         * 'reseller'
         * 'any': either or of the above.
         * @returns {Promise<void>}
         */
        async get(id, force, type) {

            if(!type){
                console.error('Missing type value calling get on CustomerDataMixin.');
                this.updateFailed(true);
            }
            if(!id){
                 throw 'ID not passed to resellerDataMixin get method.';
            }
            let resellerData = this.resellerData;
            let runGet = false;
            if(force || !this.resellerData){
                runGet = true;
            }else{
                if(this.resellerData.i_customer !== parseInt(id)){
                    runGet = true;
                }else{
                    //check if the current resellerData is the full and owner data
                    if(!this.resellerData.owners_version || !this.resellerData.extended){
                        runGet = true;
                    }
                }
            }

            if(runGet){
                //At this point we are forcing a pull. Either by force toggle. There is not data already stored. Or the passed if does not match stored id.
                if(this.resellerLoading){
                    /*
                    If we are already requesting a customer we do not need to run this process again..
                    We need this as every instance on customermixin then has an instance of refreshMixin.
                    Meaning every time a refresh event is broadcast it triggers an update on each instance of the customerMixin in the site.
                    Really it might have been better to only have 1 instance of customerMixin in the customer parent.
                    Alas this is not how the site is atm. Note the refreshMixin was added to the customerMixin at a later date to solve other issues.
                    So there is some history there.
                     */
                    return;
                }

                this.$store.commit('loading', { name: 'reseller', data: true });
                const response = await this.Api.send('get','customers/' + id + '?extended_info=1');

                if (response.success) {
                    let resellerData = response.data;
                    if(type == 'customer'){
                        if(response.data.i_customer_type != 1){
                            //redirect to wholesale user summary page.
                            this.updateFailed(true,{name: 'AdminWholesaleHome',params:{customerId:response.data.i_customer}});
                            return;
                        };
                    }else if(type == 'reseller'){
                        if(response.data.i_customer_type != 2){
                            //redirect to the customer summary page.
                            this.updateFailed(true,{name: 'CustomerSummary',params:{customerId:response.data.i_customer}});
                            return;
                        };
                    }else if(type != 'any'){
                        console.error('Invalid customer type of ('+type+')');
                        this.updateFailed(true,response.data);
                        return;
                    }

                    this.$store.commit('data', {
                        name: 'reseller',
                        data: resellerData,
                    });

                    this.$store.commit('loading', {
                        name: 'reseller',
                        data: false,
                    });
                } else {
                    this.updateFailed(response.error, response.errorDetail);
                }
            }
        },
        update() {
            this.get(this.resellerId, true,'reseller');
            //this.get(this.$route.params.customerId, true, this.forcedCustomerMixinType);
        },
        updateFailed(clear, routeData) {
            if(clear == null){
                clear = false;
            }
            let type = this.forcedCustomerMixinType;
            this.$store.commit('loading', { name: 'reseller', data: false });
            if(clear){
                this.$store.commit('data', {
                    name: 'reseller',
                    data: null,
                });
            }
            if(routeData != null){
                this.$router.push(routeData);
            }else {
                if (this.$route.name != 'WholesalesList') {
                    this.$router.push({name: 'WholesalesList'});
                }
            }
        },
    },
};