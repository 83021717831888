import Vue from 'vue';
import Vuex from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';

Vue.use(Vuex);

// TODO: package_version implementation

export default new Vuex.Store({
    state: {
        app: {
            version: process.env.VUE_APP_VERSION || '0',
            build: process.env.VUE_APP_BUILD || null,
            commit: process.env.VUE_APP_COMMIT || null,
            standalone: (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')
        },
        auth: {
            twoFactor: false,
            token: localStorage.getItem('token') || null,
        },
        user: {},
        session: {},
        permission: {},
        errors: [],
        notes: null,
        data: {},
        loading: {},
    },
    mutations: {
        increment(state) {
            state.count++;
        },
        login(state, token, twoFactor) {
            localStorage.token = token;
            state.auth.token = token;
            state.auth.twoFactor = twoFactor;
        },
        logout(state) {
            // only delete token from localstorage if it matches current session
            if (state.auth.token === localStorage.token)
                delete localStorage.token;
            state.auth.token = null;
            state.user = {};
            state.session = {};
            state.permission = {};
            state.data = {};
        },
        session(state, data) {
            // add some stuff for in app use
            data.user.isLoggedIn = !!data.user;
            data.user.isAdmin = data.user.user_type==='admin';
            data.user.isPartner = data.user.user_type==='partner';
            data.user.isCustomer = data.user.user_type==='customer';
            data.user.isManagedWholesale = data.user.user_type==='wholesale-managed';
            data.user.isByoWholesale = data.user.user_type==='wholesale-byo';
            data.user.isNotCustomer = data.user.user_type!=='customer';
            data.user.isNotAdmin = data.user.user_type!=='admin';
            // save data
            state.user = data.user;
            state.session = data.session;
            state.permission = data.permission;
        },
        data(state, payload) {
            if (payload.data !== null) {
                if (payload.update) {
                    Vue.set(state.data, payload.name, { ...state.data[payload.name], ...payload.data });
                } else {
                    Vue.set(state.data, payload.name, payload.data);
                }
            } else {
                Vue.delete(state.data, payload.name);
            }
        },
        loading(state, payload) {
            if(payload.data == null){
                Vue.delete(state.loading, payload.name);
            }else {
                Vue.set(state.loading, payload.name, payload.data);
            }
        },
        darkmode(state, pref) {
            if (pref) {
                localStorage.darkmode = 1;
            } else {
                delete localStorage.darkmode;
            }
            state.prefs.theme.dark = pref;
        },
        updateNotes(state, notes) {
            state.notes = notes;
        },
        showError(state, error) {
            state.errors.push(error);
        },
        closeError(state, index) {
            state.errors.splice(index);
        },
    },
    actions: {},
    modules: {},
    getters: {},
    plugins: [
        createMultiTabState({
            statesPaths: ['notes'],
            key: 'breeze-portal-data',
        }),
    ],
});
