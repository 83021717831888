<template>
    <v-slide-y-transition>
        <v-banner
            v-show="visible"
            app
            class="elevation-7"
            style="z-index: 5"
            :class="{
                'blue darken-4': $vuetify.theme.dark,
                'blue lighten-5': !$vuetify.theme.dark,
            }"
        >
            <v-icon slot="icon" :color="color" size="36">
                {{ icon }}
            </v-icon>
            {{ message }}
            <template v-slot:actions>
                <v-btn text @click="dismissBanner">
                    {{ dismiss }}
                </v-btn>
                <v-btn color="primary" text @click="acceptBanner">
                    {{ accept }}
                </v-btn>
            </template>
        </v-banner>
    </v-slide-y-transition>
</template>

<script>
export default {
    name: 'Banner',
    data: () => ({
        visible: false,
    }),
    watch: {
        show: function (value) {
            this.visible = value;
        },
    },
    methods: {
        dismissBanner: function () {
            this.$emit('dismiss');
        },
        acceptBanner: function () {
            this.$emit('accept');
        },
    },
    props: {
        icon: {
            type: String,
            default: 'mdi-alert-circle',
        },
        color: {
            type: String,
            default: 'info',
        },
        message: {
            type: String,
            default: '',
        },
        accept: {
            type: String,
            default: 'Accept',
        },
        dismiss: {
            type: String,
            default: 'Dismiss',
        },
        show: {
            type: [Boolean, String],
            default: false,
        },
    },
    created() {
        this.visible = this.show;
    },
};
</script>
