<template>
    <div>
        <v-menu
            bottom
            offset-y
            :close-on-content-click="false"
            transition="slide-y-transition"
            v-model="showMenu"
        >
            <template #activator="{ on: onMenu }">
                <v-tooltip bottom open-delay="200">
                    <template #activator="{ on: onTooltip }">
                        <v-avatar
                            v-on="{ ...onMenu, ...onTooltip }"
                            color="grey"
                            size="42"
                            style="cursor: pointer"
                        >
                            <div class="white--text title">
                                {{ $store.state.user.firstname | firstLetter }}
                            </div>
                        </v-avatar>
                    </template>
                    <span>My account</span>
                </v-tooltip>
            </template>
            <v-card :min-width="300">
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar color="grey" size="42">
                            <div class="white--text title">
                                {{ $store.state.user.firstname | firstLetter }}
                            </div>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title
                                >{{ $store.state.user.firstname }}
                                {{
                                    $store.state.user.lastname
                                }}</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                                $store.state.user.login
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item @click="goTo('/account')">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>My Account</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="toggleTheme" v-if="$vuetify.breakpoint.xsOnly">
                        <v-list-item-action>
                            <v-icon v-html="$vuetify.theme.dark ? 'mdi-brightness-5' : 'mdi-brightness-3'" />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Switch to <span v-if="$vuetify.theme.dark"
                                    >light</span
                                ><span v-else>dark</span> theme
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>
                <v-card-actions>
                    <span class="text--disabled text-sm-caption"
                        >v{{ $store.state.app.version }} <span v-if="$store.state.app.build">(build #{{ $store.state.app.build }})</span>
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="logout">
                        <v-icon left>mdi-logout</v-icon>
                        Logout
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import apiMixin from '../../mixins/apiMixin';
export default {
    name: 'UserMenu',
    mixins: [apiMixin],
    props: ['data'],
    methods: {
        goTo: function (path) {
            this.$router.push({ path: path }).catch(() => {}); // added catch for when .push is redirected by nav guard
            this.showMenu = false;
        },
        toggleTheme: function () {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$store.commit('darkmode', this.$vuetify.theme.dark);
            this.showMenu = false;
        },
        async logout() {
            this.busy = true;
            await this.Api.send('post','auth/logout', {});
            this.$store.commit('logout');
            this.showMenu = false;
            this.$router.push({ name: 'Login' });
        },
    },
    data: () => ({
        showMenu: false,
        busy: false,
    }),
    filters: {
        firstLetter: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase();
        },
    },
};
</script>
